import React from 'react'
import Layout from '../../../../../component/layout/Layout';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const BurstingStrengthTester = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000
    };

    const images = [
        '/img/instruments/bursting_strength_tester.png',
        '/img/instruments/bursting_strength_tester.png'
    ];

    return (
        <Layout>
            <div className="bread">
                <ul className="collapsed">
                    <li><a href="/">Home</a></li>
                    <li><a href="/packaingtesting">Packaging Testing</a></li>
                </ul>
            </div>
            <div className="container">
                <div className="section-title text-center" style={{ marginBottom: '2rem' }}>
                    <h1>Bursting Strength Tester</h1>
                </div>
            </div>
            <section id="portfolio-details" className="portfolio-details" style={{ backgroundColor: 'white', marginBottom: '12rem' }}>
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-8">
                            <div className="portfolio-details-slider swiper">
                                <Slider {...settings}>
                                    {images.map((src, index) => (
                                        <div className="swiper-slide" key={index}>
                                            <div className="img" style={{ alignItems: 'center' }}>
                                                <img src={src} alt="" className="responsive" />
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                                <div className="swiper-pagination"></div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="portfolio-info" style={{ backgroundColor: 'white' }}>
                                <h3 className='pageheading'>Bursting Strength Tester</h3>
                                <p className='descriptionHead' style={{ textAlign: 'justify' }}>
                                    For measuring the bursting strength on materials such as paper, compact and corrugated cardboard
                                    "Mullen type”.
                                    Bursting Strength Tester, to test the bursting strength of paper, compact and corrugated
                                    cardboard by the of hydraulic load under a Burst Tester diaphragm of a specific area. After
                                    placing the paper or cardboard sample in its test position, under the acrylic protective screen,
                                    the TEST button is activated on the screen of the supplied PC. The methacrylate protective
                                    screen descends and then the pneumatic piston, closing the specimen holder. Next, the hydraulic
                                    pressure device acts on the sample. When the sample bursts, the hydraulic cylinder stops
                                    automatically and reverses the direction of travel until it returns to its initial test
                                    position.
                                </p>
                            </div>
                            <div className="portfolio-description"></div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default BurstingStrengthTester
