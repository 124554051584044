import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ChatBot.css";

const Chatbot = () => {
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [chatMessages, setChatMessages] = useState([]);
    const [userInput, setUserInput] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [userId, setUserId] = useState(null); // Store user ID after login

    const departments = [
        "Bulk Drugs",
        "Speciality Chemicals",
        "Instruments",
        "Paper and Pulp",
        "Water Waste Management",
        "Textile",
    ];

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const getCurrentTime = () => {
        const now = new Date();
        let hours = now.getHours();
        const minutes = String(now.getMinutes()).padStart(2, "0");
        const ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12 || 12;
        return `${hours}:${minutes} ${ampm}`;
    };

    const handleUserMessage = async (input) => {
        const userMessage = { type: "user", text: input, time: getCurrentTime() };
        setChatMessages((prevMessages) => [...prevMessages, userMessage]);
    
        if (userId) {
            // Store the user's message in the Firestore database
            const db = firebase.firestore();
            try {
                await db.collection("ChatMessages").add({
                    userId,
                    message: input,
                    type: "user",
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                });
            } catch (error) {
                console.error("Error saving user message to database:", error);
            }
        }
    
        setIsTyping(true);
    
        try {
            const response = await fetch("https://chatbotwhatsapp-zuo9.onrender.com/chat", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ department: input }),
            });
    
            const data = await response.json();
            const botMessage = {
                type: "bot",
                text: data.message,
                time: getCurrentTime(),
                whatsappLink: data.whatsappLink,
            };
            setChatMessages((prevMessages) => [...prevMessages, botMessage]);
        } catch (error) {
            const errorMessage = {
                type: "bot",
                text: "Sorry, something went wrong. Please try again.",
                time: getCurrentTime(),
            };
            setChatMessages((prevMessages) => [...prevMessages, errorMessage]);
            console.error("Error:", error);
        } finally {
            setIsTyping(false);
        }
    };
    

    const handleSendMessage = () => {
        if (userInput.trim()) {
            handleUserMessage(userInput.trim());
            setUserInput("");
        }
    };

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        const username = e.target[0].value;
        const email = e.target[1].value;
        const phone = e.target[2].value;

        try {
            // Add user data to Firestore with a timestamp
            const db = firebase.firestore();
            const userRef = await db.collection("LoginChatbot").add({
                username,
                email,
                phone,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            });

            setUserId(userRef.id); // Store the user ID after login
            toast.success("Login successful! Welcome!");
            setIsLoginOpen(false);
            setIsChatOpen(true); // Open chatbot after successful login
        } catch (error) {
            toast.error("Failed to log in. Please try again.");
            console.error("Error storing login details:", error);
        }
    };

    return (
        <div className="chatbot-start">
            <ToastContainer position="top-right" autoClose={3000} />

            <button
                id="chatbot-toggle"
                className="chatbot-toggle"
                onClick={() => setIsLoginOpen(true)}
                style={{
                    display: isChatOpen || isLoginOpen ? "none" : "block",
                    backgroundColor: isScrolled ? "#e8f2fe" : "#fff",
                    border: "none",
                    padding: "10px",
                    borderRadius: "50%",
                    cursor: "pointer",
                    width: "100px",
                    height: "100px",
                    transition: "background-color 0.3s",
                }}
            >
                <img src="./chemboticon.png" alt="Chatbot" className="chatbot-image" />
            </button>

            {isLoginOpen && (
                <div className="login-popup">
                    <div className="login-form">
                        <h2>Login</h2>
                        <form onSubmit={handleLoginSubmit}>
                            <input type="text" placeholder="Username" className="login-input" required />
                            <input type="email" placeholder="Email ID" className="login-input" required />
                            <input type="tel" placeholder="Phone Number" className="login-input" required />
                            <button type="submit" className="login-button">Login</button>
                        </form>
                        <button className="close-login" onClick={() => setIsLoginOpen(false)}>
                            <i className="fas fa-times"></i>
                        </button>
                    </div>
                </div>
            )}

            {isChatOpen && (
                <div className="chat-container" id="chat-container">
                    <div className="chat-header">
                        <img src="./chatbotlogo.png" alt="Logo" className="chatlogo" />
                        <div className="header-icons">
                            <button className="icon-button reload-button" onClick={() => setChatMessages([])}>
                                <i className="fa-solid fa-rotate"></i>
                            </button>
                            <button
                                className="icon-button fullscreen-button"
                                onClick={() =>
                                    document.getElementById("chat-container").classList.toggle("fullscreen")
                                }
                            >
                                <i className="fas fa-expand"></i>
                            </button>
                            <button className="icon-button close-button" onClick={() => setIsChatOpen(false)}>
                                <i className="fas fa-times"></i>
                            </button>
                        </div>
                    </div>

                    <div className="chat-body">
                        <p className="welcome-message">
                            Hi! Welcome to Rishabh Metals, Plz Select the Departments
                        </p>
                        <div className="button-container">
                            {departments.map((dept, index) => (
                                <button
                                    key={index}
                                    className="chat-button"
                                    onClick={() => handleUserMessage(dept)}
                                >
                                    {dept}
                                </button>
                            ))}
                        </div>
                        <div className="chatbox" id="chatbox">
                            {chatMessages.map((msg, index) => (
                                <div key={index} className={`${msg.type}-message`}>
                                    <p className="paragraph">
                                        {msg.type === "bot" ? "ChemBot:" : "User:"} {msg.text}
                                    </p>
                                    {msg.whatsappLink && (
                                        <a href={msg.whatsappLink} target="_blank" rel="noopener noreferrer">
                                            <i className="fa-brands fa-whatsapp"></i> Contact on WhatsApp
                                        </a>
                                    )}
                                    <span className="message-time">{msg.time}</span>
                                </div>
                            ))}
                        </div>
                        {isTyping && (
                            <div className="typing-indicatorchatbot" id="typing-indicator">
                                <i className="fa fa-spinner fa-spin"></i> Typing...
                            </div>
                        )}
                    </div>

                    <div className="footer-placement">
                        <div className="chat-footer">
                            <input
                                type="text"
                                className="input-box"
                                value={userInput}
                                onChange={(e) => setUserInput(e.target.value)}
                                onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
                                placeholder="Ask me something.."
                            />
                            <button className="send-button" onClick={handleSendMessage}>
                                <i className="fa-solid fa-paper-plane"></i>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Chatbot;
