import React from 'react'
import Layout from '../../../../../component/layout/Layout'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CODMonitor = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000
    };

    const images = [
        '/img/instruments/COD.jpg',
        '/img/instruments/COD.jpg'
    ];

    return (
        <Layout>
            <div className="bread">
                <ul className="collapsed">
                    <li><a href="/">Home</a></li>
                    <li><a href="/wastewater">Water Waste</a></li>
                </ul>
            </div>
            <div className="container">
                <div className="section-title text-center" style={{ marginBottom: '2rem' }}>
                    <h1>COD Monitor, BOD Analyzer</h1>
                </div>
            </div>
            <section id="portfolio-details" className="portfolio-details" style={{ backgroundColor: 'white', marginBottom: '12rem' }}>
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-8">
                            <div className="portfolio-details-slider swiper">
                                <Slider {...settings}>
                                    {images.map((src, index) => (
                                        <div className="swiper-slide" key={index}>
                                            <div className="img" style={{ alignItems: 'center' }}>
                                                <img src={src} alt="" className="responsive" />
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                                <div className="swiper-pagination"></div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="portfolio-info" style={{ backgroundColor: 'white' }}>
                                <h3 className='pageheading'>COD Monitor, BOD Analyzer</h3>
                                <p className='descriptionHead' style={{ textAlign: 'justify' }}>
                                    It can measure pH, ORP, ion concentration, conductivity, resistivity, TDS, salinity, dissolve Oxygen, saturation
                                    and Temperature at the same time.
                                    It provide many ion modes such as H+, Ag+, Na+, K+, NH4+, Cl-, F-, NO3-, BF4-, CN-, Cu2+, Pb2+, Ca2+.
                                    The users can define ion modes by   choosing corresponding ion sensors.
                                    Typical applications is entire Process control, monitoring of inflows and outflows in treatment plants,
                                    determination of peak concentrations and measurement of production losses, e.g. in food manufacturing and
                                    the chemical industry.
                                </p>
                            </div>
                            <div className="portfolio-description"></div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default CODMonitor
