import React from 'react'
import Layout from '../../../../../component/layout/Layout'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const HighPressViscometer = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000
    };

    const images = [
        '/img/instruments/HighpressViscometer.jpg',
        '/img/instruments/HighpressViscometer.jpg'
    ];

    return (
        <Layout>
            <div className="bread">
                <ul className="collapsed">
                    <li><a href="/">Home</a></li>
                    <li><a href="/industrial">Industrial</a></li>
                </ul>
            </div>
            <div className="container">
                <div className="section-title text-center" style={{ marginBottom: '2rem' }}>
                    <h1>High Press Vicometer</h1>
                </div>
            </div>
            <section id="portfolio-details" className="portfolio-details" style={{ backgroundColor: 'white', marginBottom: '12rem' }}>
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-8">
                            <div className="portfolio-details-slider swiper">
                                <Slider {...settings}>
                                    {images.map((src, index) => (
                                        <div className="swiper-slide" key={index}>
                                            <div className="img" style={{ alignItems: 'center' }}>
                                                <img src={src} alt="" className="responsive" />
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                                <div className="swiper-pagination"></div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="portfolio-info" style={{ backgroundColor: 'white' }}>
                                <h3 className='pageheading'>High Press Vicometer</h3>
                                <p className='descriptionHead' style={{ textAlign: 'justify' }}>
                                    Viscosity is very important criteria in process .To measure the viscosity at high temp. & pressure
                                    in reaction vessel is a critical phenomenon. This instrument operates on resonance frequency principle
                                    which is least sensitive to Vibration, shocks etc. Since there is no moving part, no wear & tear,No frequent
                                    maintenance & calibration. Process viscometer is designed to measure viscosity directly in Industrial Process
                                    by mounting on reactor or pipes in any position. It is compatible with process conditions like temp.  Press,
                                    corrosion, clogging, humidity etc. It can go up to 350 * C temperature & 500 bar pressure.
                                </p>
                            </div>
                            <div className="portfolio-description"></div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default HighPressViscometer
