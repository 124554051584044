import React from 'react'
import Layout from '../../../../../component/layout/Layout'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const AbsorbencyTester = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000
    };

    const images = [
        '/img/instruments/Absorbency-Tester.png',
        '/img/instruments/Absorbency-Tester.png'
    ];

    return (
        <Layout>
            <div className="bread">
                <ul className="collapsed">
                    <li><a href="/">Home</a></li>
                    <li><a href="/textile">Textile</a></li>
                </ul>
            </div>
            <div className="container">
                <div className="section-title text-center" style={{ marginBottom: '2rem' }}>
                    <h1>Absorbency Tester</h1>
                </div>
            </div>
            <section id="portfolio-details" className="portfolio-details" style={{ backgroundColor: 'white', marginBottom: '12rem' }}>
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-8">
                            <div className="portfolio-details-slider swiper">
                                <Slider {...settings}>
                                    {images.map((src, index) => (
                                        <div className="swiper-slide" key={index}>
                                            <div className="img" style={{ alignItems: 'center' }}>
                                                <img src={src} alt="" className="responsive" />
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                                <div className="swiper-pagination"></div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="portfolio-info" style={{ backgroundColor: 'white' }}>
                                <h3 className='pageheading'>Absorbency Tester</h3>
                                <p className='descriptionHead' style={{ textAlign: 'justify' }}>
                                    The GATS is a precise and reliable absorbency measurement device, utilizing patented liquid interface technology to accurately assess absorbency rates and capacity. Its water delivery method maintains precision by controlling the height between the sample and the liquid source. Ideal for replacing PFI absorbency testers, the GATS is versatile, measuring absorbency in various materials such as textiles, cotton, polymers, tissues, paper towels, fibers, and more. Its different liquid interface designs enable testing under load, z-directional absorbency, radial wicking, and 45-degree absorbency. Common applications include towels, diapers, tampons, and analysis of polymer absorption properties. The GATS is a valuable tool for industries working with absorbent materials.                                </p>
                            </div>
                            <div className="portfolio-description"></div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default AbsorbencyTester
