import React from 'react'
import Layout from '../../../../../../component/layout/Layout'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const SurfaceTesting = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000
    };

    const images = [
        '/img/instruments/EST_12_Surface__sizing_tester.png',
        '/img/instruments/EST_12_Surface__sizing_tester.png'
    ];

    return (
        <Layout>
            <div className="bread">
                <ul className="collapsed">
                    <li><a href="/">Home</a></li>
                    <li><a href="/PPwetendQC">Wet End QC</a></li>
                </ul>
            </div>
            <div className="container">
                <div className="section-title text-center" style={{ marginBottom: '2rem' }}>
                    <h1>Surface Testing</h1>
                </div>
            </div>
            <section id="portfolio-details" className="portfolio-details" style={{ backgroundColor: 'white', marginBottom: '12rem' }}>
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-8">
                            <div className="portfolio-details-slider swiper">
                                <Slider {...settings}>
                                    {images.map((src, index) => (
                                        <div className="swiper-slide" key={index}>
                                            <div className="img" style={{ alignItems: 'center' }}>
                                                <img src={src} alt="" className="responsive" />
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                                <div className="swiper-pagination"></div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="portfolio-info" style={{ backgroundColor: 'white' }}>
                                <h3 className='pageheading'>Surface Testing</h3>
                                <p className='descriptionHead' style={{ textAlign: 'justify' }}>
                                    The EST- 12 instruments allows to characterize the surface properties of paper board by recording the
                                    Dynamic  Properties  of liquid absorption from the moment of liquid contact, it can measure the absorbility
                                    of the paper  with the liquids of the  lowest to medium viscosity, as well as printability, glue Ability &
                                    suitability for coated  paper board with test liquids like water, foundation solutions, mineral Oil, gravure inks,
                                    flexographic printing inks, inkjet inks in offset, Gravure printing inks, etc. From EST – 12 three results can be
                                    achieved.
                                </p>
                            </div>
                            <div className="portfolio-description"></div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default SurfaceTesting
