import React from 'react'
import Layout from '../../../../../../component/layout/Layout'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const PenuematicPress = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000
    };

    const images = [
        '/img/instruments/penuematic-press.png',
        '/img/instruments/penuematic-press.png'
    ];


  return (
    <Layout>
       <div className="bread">
                <ul className="collapsed">
                    <li><a href="/">Home</a></li>
                    <li><a href="/paperpulp">Paper</a></li>
                </ul>
            </div>
            <div className="container">
                <div className="section-title text-center" style={{ marginBottom: '2rem' }}>
                    <h1>Penuematic Press</h1>
                </div>
            </div>
            <section id="portfolio-details" className="portfolio-details" style={{ backgroundColor: 'white', marginBottom: '12rem' }}>
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-8">
                            <div className="portfolio-details-slider swiper">
                                <Slider {...settings}>
                                    {images.map((src, index) => (
                                        <div className="swiper-slide" key={index}>
                                            <div className="img" style={{ alignItems: 'center' }}>
                                                <img src={src} alt="" className="responsive" />
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                                <div className="swiper-pagination"></div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="portfolio-info" style={{ backgroundColor: 'white' }}>
                                <h3 className='pageheading'>Penuematic Press</h3>
                                {/* <p className='descriptionHead' style={{ textAlign: 'justify' }}>
                                    To perform compression and resistance tests to packaging according to International Standard specifications.
                                    LYNX TM Software for Packing & Packaging Compression Test + LYNX Management Module with basic statistics,
                                    with control from PC in closed loop using our software test pack with 3 test modules . The LYNX Management
                                    Module allows you to manage the data generated, choose the interface language, and prepare and print reports
                                    (that you can customize with your logo). You can also change the units displayed, as well as change user accounts
                                    and passwords. You can also record the minimum, maximum and optimal values to manage data with statistics,
                                    charts bars, GAUSSEN Bell, tolerances comparatives and export this data to Word, Excel, PDF and more.
                                </p> */}
                            </div>
                            <div className="portfolio-description"></div>
                        </div>
                    </div>
                </div>
            </section>
    </Layout>
  )
}

export default PenuematicPress
