import React from 'react'
import Layout from '../../../../../component/layout/Layout'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CobbSizingTester = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000
    };

    const images = [
        '/img/instruments/Cobb_sizing_tester.png',
        '/img/instruments/Cobb_sizing_tester.png'
    ];

    return (
        <Layout>
            <div className="bread">
                <ul className="collapsed">
                    <li><a href="/">Home</a></li>
                    <li><a href="/packaingtesting">Packaging Testing</a></li>
                </ul>
            </div>
            <div className="container">
                <div className="section-title text-center" style={{ marginBottom: '2rem' }}>
                    <h1>Cobb Sizing Tester</h1>
                </div>
            </div>
            <section id="portfolio-details" className="portfolio-details" style={{ backgroundColor: 'white', marginBottom: '12rem' }}>
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-8">
                            <div className="portfolio-details-slider swiper">
                                <Slider {...settings}>
                                    {images.map((src, index) => (
                                        <div className="swiper-slide" key={index}>
                                            <div className="img" style={{ alignItems: 'center' }}>
                                                <img src={src} alt="" className="responsive" />
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                                <div className="swiper-pagination"></div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="portfolio-info" style={{ backgroundColor: 'white' }}>
                                <h3 className='pageheading'>Cobb Sizing Tester</h3>
                                <p className='descriptionHead' style={{ textAlign: 'justify' }}>
                                    To determine the water absorption capacity of paper and corrugated cardboard (quick clamping
                                    grip).The Cobb testing equipment allows to measure the amount of liquid that penetrates a sheet of
                                    paper and cardboard. The definition of the Cobb value for a degree of gluing is the calculation
                                    of the weight of water absorbed in a specified time per 1 m2 of paper or cardboard under
                                    standard conditions (ISO 535).COBB Tester with a quick clamping grip, on a metal base covered with a rubber sheet.200 mm polished metal roller. length, a diameter of 90 mm and a weight of 10 ± 0.5 Kg.Non-absorbent soft rubber ring to avoid lateral water seepage.
                                </p>
                            </div>
                            <div className="portfolio-description"></div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default CobbSizingTester
