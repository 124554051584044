import React from 'react'
import Layout from '../../../../../component/layout/Layout'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const TactileSensation = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000
    };

    const images = [
        '/img/instruments/TSA.png',
        '/img/instruments/TSA.png'
    ];

    return (
        <Layout>
            <div className="bread">
                <ul className="collapsed">
                    <li><a href="/">Home</a></li>
                    <li><a href="/textile">Textile</a></li>
                </ul>
            </div>
            <div className="container">
                <div className="section-title text-center" style={{ marginBottom: '2rem' }}>
                    <h1>Tactile Sensation Analyser</h1>
                </div>
            </div>
            <section id="portfolio-details" className="portfolio-details" style={{ backgroundColor: 'white', marginBottom: '12rem' }}>
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-8">
                            <div className="portfolio-details-slider swiper">
                                <Slider {...settings}>
                                    {images.map((src, index) => (
                                        <div className="swiper-slide" key={index}>
                                            <div className="img" style={{ alignItems: 'center' }}>
                                                <img src={src} alt="" className="responsive" />
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                                <div className="swiper-pagination"></div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="portfolio-info" style={{ backgroundColor: 'white' }}>
                                <h3 className='pageheading'>Tactile Sensation Analyser</h3>
                                <p className='descriptionHead' style={{ textAlign: 'justify' }}>
                                    The Industrial R&D and QC Control Instrument is a user-friendly, cost-effective solution designed for efficiency. It provides rapid results within a minute, measuring softness, roughness, and tensile strength simultaneously without the need for complex calculations. The latest model integrates objective measurement for softness, roughness, and hand feel value calculation, also assessing elasticity and recovery. Known for its accuracy and reliability, the instrument correlates well with human perception. It excels in capturing surface thermal conductivity, offering high-resolution pictures for a comprehensive analysis. In essence, it's a versatile tool for industrial research, development, and quality control.
                                </p>
                            </div>
                            <div className="portfolio-description"></div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default TactileSensation
