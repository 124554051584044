import React from 'react'
import Layout from '../../../../../../component/layout/Layout'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ChargeAnalyserSystem = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000
    };

    const images = [
        '/img/instruments/ChargeAnalyserSystem.png',
        '/img/instruments/ChargeAnalyserSystem.png'
    ];

    return (
        <Layout>
            <div className="bread">
                <ul className="collapsed">
                    <li><a href="/">Home</a></li>
                    <li><a href="/paperpulp">Paper</a></li>
                </ul>
            </div>
            <div className="container">
                <div className="section-title text-center" style={{ marginBottom: '2rem' }}>
                    <h1>Charge Analyser System</h1>
                </div>
            </div>
            <section id="portfolio-details" className="portfolio-details" style={{ backgroundColor: 'white', marginBottom: '12rem' }}>
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-8">
                            <div className="portfolio-details-slider swiper">
                                <Slider {...settings}>
                                    {images.map((src, index) => (
                                        <div className="swiper-slide" key={index}>
                                            <div className="img" style={{ alignItems: 'center' }}>
                                                <img src={src} alt="" className="responsive" />
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                                <div className="swiper-pagination"></div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="portfolio-info" style={{ backgroundColor: 'white' }}>
                                <h3 className='pageheading'>Charge Analyser System</h3>
                                <p className='descriptionHead' style={{ textAlign: 'justify' }}>
                                    CAS accurately measures dissolved colloidal / ionic charge of a liquid using streaming current principle which
                                    is comparable to the Zeta Potential in dilution. The charge demand is determined with Polyelectrolyte
                                    titration to the isoelectric point either manually or by use of an automatic titrator. CAS is having the features
                                    like user friendly operation. Same volume required for analysis and Measurement is 10ml. Sturdy housing
                                    for easy mobility, quick set of and long term stability. Detachable probe for facilitate cleaning and prevent
                                    corrosion. The instrument is easy to operate and it facilitated with built-in automatic titrator.
                                </p>
                            </div>
                            <div className="portfolio-description"></div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default ChargeAnalyserSystem
