import React from 'react'
import Layout from '../../../../../component/layout/Layout'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const PenetrationTest = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000
    };

    const images = [
        '/img/instruments/Penetrationtestanalyser.png',
        '/img/instruments/Penetrationtestanalyser.png'
    ];


    return (
        <Layout>
            <div className="bread">
                <ul className="collapsed">
                    <li><a href="/">Home</a></li>
                    <li><a href="/industrial">Industrial</a></li>
                </ul>
            </div>
            <div className="container">
                <div className="section-title text-center" style={{ marginBottom: '2rem' }}>
                    <h1>Penetration Test Analyser</h1>
                </div>
            </div>
            <section id="portfolio-details" className="portfolio-details" style={{ backgroundColor: 'white', marginBottom: '12rem' }}>
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-8">
                            <div className="portfolio-details-slider swiper">
                                <Slider {...settings}>
                                    {images.map((src, index) => (
                                        <div className="swiper-slide" key={index}>
                                            <div className="img" style={{ alignItems: 'center' }}>
                                                <img src={src} alt="" className="responsive" />
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                                <div className="swiper-pagination"></div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="portfolio-info" style={{ backgroundColor: 'white' }}>
                                <h3 className='pageheading'>Penetration Test Analyser</h3>
                                <p className='descriptionHead' style={{ textAlign: 'justify' }}>
                                    The “MAGNESS-TAYLOR” probes set is suitable for puncture and compression penetration tests on fruits,
                                    vegetables and other food products. Once the test conditions have been established (pre-loads – test speed –
                                    penetration on the sample …), the sample is placed between the test tools and the test start button is pressed on
                                    the Touch Screen or by means of the mouse. After the end of the test, the upper mobile crosspiece returns to its
                                    initial test position and the equipment is ready for a subsequent test automatically. This Set of Magness-Taylor
                                    probes incorporated into a Texturometer is widely used in evaluating the texture of fruits and vegetables,
                                    measuring resistance to penetration, which is a key indicator of maturity
                                </p>
                            </div>
                            {/* <div className="portfolio-description"></div> */}
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default PenetrationTest
