// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCwIUC10-FvWgDH5BvggCTS2-NTnCf_nIU",
    authDomain: "rmcpl-8a920.firebaseapp.com",
    projectId: "rmcpl-8a920",
    storageBucket: "rmcpl-8a920.appspot.com",
    messagingSenderId: "1043755967619",
    appId: "1:1043755967619:web:f07a7e785794a9cb08a7b6",
    measurementId: "G-9P03GS5L50"
};

export default firebaseConfig;