import React from 'react'
import Layout from '../../../../../component/layout/Layout'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const ParticlesizeMeasurement = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000
    };

    const images = [
        '/img/instruments/ParticlesizeMeasurement.jpg',
        '/img/instruments/ParticlesizeMeasurement.jpg'
    ];


    return (
        <Layout>
            <div className="bread">
                <ul className="collapsed">
                    <li><a href="/">Home</a></li>
                    <li><a href="/rd">R & D</a></li>
                </ul>
            </div>
            <div className="container">
                <div className="section-title text-center" style={{ marginBottom: '2rem' }}>
                    <h1>Particle size Measurement </h1>
                </div>
            </div>
            <section id="portfolio-details" className="portfolio-details" style={{ backgroundColor: 'white', marginBottom: '12rem' }}>
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-8">
                            <div className="portfolio-details-slider swiper">
                                <Slider {...settings}>
                                    {images.map((src, index) => (
                                        <div className="swiper-slide" key={index}>
                                            <div className="img" style={{ alignItems: 'center' }}>
                                                <img src={src} alt="" className="responsive" />
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                                <div className="swiper-pagination"></div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="portfolio-info" style={{ backgroundColor: 'white' }}>
                                <h3 className='pageheading'>Particle size Measurement </h3>
                                <p className='descriptionHead' style={{ textAlign: 'justify' }}>
                                    The probe is inserted into a product stream or in defined distance above. Xenon flash light is integrated in the
                                    probe for sharp images and fast acquisition. A CCD camera is taking the images which are then analysed and
                                    displayed online and results stored. Measurement range: from 10 micron  to  3.0 mm. Suitable for Measurement
                                    of particle size, shape and count Particle shape detection in suspension or dry Bubbles in liquid ,Food, pharma,
                                    polymers, chemicals, oils...Detection of black-specks etc.
                                </p>
                            </div>
                            <div className="portfolio-description"></div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default ParticlesizeMeasurement
